import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

const container = document.getElementById('root');

const analyticsGtagScript = document.createElement("script")
analyticsGtagScript.async = true
analyticsGtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-8CGK5RKF39"

const analyticsScript = document.createElement("script")
analyticsScript.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-8CGK5RKF39');
`

document.getElementsByTagName('head')[0].after(analyticsScript)
document.getElementsByTagName('head')[0].after(analyticsGtagScript)

const root = createRoot(container);
root.render(<App tab="home" />);
